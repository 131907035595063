.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: $breadcrumb-margin-bottom;
  @include font-size($breadcrumb-font-size);
  list-style: none;
  background-color: var(--#{$variable-prefix}breadcrumb-bg, $breadcrumb-bg);
  @include border-radius($breadcrumb-border-radius);
}

.breadcrumb-item {
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item {
    @include ltr-rtl("padding-left", $breadcrumb-item-padding-x);

    &::before {
      @include ltr-rtl("float", left); // Suppress inline spacings and underlining of the separator
      @include ltr-rtl("padding-right", $breadcrumb-item-padding-x);
      color: var(--#{$variable-prefix}breadcrumb-divider-color, $breadcrumb-divider-color);
      @include ltr-rtl(
        "content",
        var(--#{$variable-prefix}breadcrumb-divider, escape-svg($breadcrumb-divider)),
        null,
        var(--#{$variable-prefix}breadcrumb-divider-flipped, escape-svg($breadcrumb-divider-flipped))
      );
    }
  }

  &.active {
    color: var(--#{$variable-prefix}breadcrumb-active-color, $breadcrumb-active-color);
  }
}
