// Body
$body-bg: #fff;

// Typography
$font-family-sans-serif: 'Cairo', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$highlight-blue: #d5e8f8;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #239130;
$teal: #4dc0b5;
$cyan: #93c2e9;
$darkgreen:#1A3A1E;
