// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
/* stylelint-disable-line scss/dollar-variable-default */
$enable-rtl: true;
/* stylelint-disable-line scss/dollar-variable-default */

// Import styles
@import "coreui/scss/coreui";

@import "layout";

// Prism.js
@import "examples";

// If you want to add custom CSS you can put it here.
@import "custom";

@import "simplebar";

* {
    font-size: .9rem;
    font-family: 'Almarai', sans-serif;

}

.ltr {
    direction: ltr;
}

.rtl {
    direction: rtl;
}

@keyframes spin_first {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    position: absolute;
    animation: spin_first 1.5s infinite;
    z-index: 1;
    top: 45%;
    left: 50%;
}

small table * {
    font-size: 0.8rem;
}


.bg-blue {
    background-color: $blue;
}
.highlight-blue{
    background-color: $highlight-blue;

}
.bg-indigo {
    background-color: $indigo;
}

.bg-purple {
    background-color: $purple;
}

.bg-pink {
    background-color: $pink;
}

.bg-red {
    background-color: $red;
}

.bg-orange {
    background-color: $orange;
}

.bg-yellow {
    background-color: $yellow;
}

.bg-green {
    background-color: $green;
}

.bg-teal {
    background-color: $teal;
}

.bg-cyan {
    background-color: $cyan;
}

.bg-darkgreen {
    background-color: $darkgreen;
}

.text-blue {
    background-color: $blue;
}

.text-indigo {
    background-color: $indigo;
}

.text-purple {
    background-color: $purple;
}

.text-pink {
    background-color: $pink;
}

.text-red {
    background-color: $red;
}

.text-orange {
    background-color: $orange;
}

.text-yellow {
    background-color: $yellow;
}

.text-green {
    background-color: $green;
}

.text-teal {
    background-color: $teal;
}

.text-cyan {
    background-color: $cyan;
}

.text-darkgreen {
    background-color: $darkgreen;
}
