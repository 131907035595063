// Disable animation if transitions are disabled

// scss-docs-start progress-keyframes
@if $enable-transitions {
  @keyframes progress-bar-stripes {
    0% { background-position-x: $progress-height; }
  }
}
// scss-docs-end progress-keyframes

.progress {
  display: flex;
  height: $progress-height;
  overflow: hidden; // force rounded corners by cropping it
  @include font-size($progress-font-size);
  background-color: var(--#{$variable-prefix}progress-bg, $progress-bg);
  @include border-radius($progress-border-radius);
  @include box-shadow($progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--#{$variable-prefix}progress-bar-color, $progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--#{$variable-prefix}progress-bar-bg, $progress-bar-bg);
  @include transition($progress-bar-transition);
}

.progress-bar-striped {
  @include gradient-striped();
  background-size: $progress-height $progress-height;
}

@if $enable-transitions {
  .progress-bar-animated {
    animation: $progress-bar-animation-timing progress-bar-stripes;

    @if $enable-reduced-motion {
      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }
    }
  }
}

.progress-thin {
  height: 4px;
}

// White progress bar
.progress.progress-white {
  background-color: rgba(255, 255, 255, .2);
  .progress-bar {
    background-color: $white;
  }
}

.progress-group {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: $progress-group-margin-bottom;
}

.progress-group-prepend {
  flex: 0 0 100px;
  align-self: center;
}

.progress-group-header {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin-bottom: $progress-group-header-margin-bottom;
}

.progress-group-bars {
  flex-grow: 1;
  align-self: center;

  .progress:not(:last-child) {
    margin-bottom: 2px;
  }
}

.progress-group-header + .progress-group-bars {
  flex-basis: 100%;
}
