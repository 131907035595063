// scss-docs-start alert-variant-mixin
@mixin alert-variant($state, $variant) {
  $background: map-get($variant, "bg");
  $border-color: map-get($variant, "border-color");
  $color: contrast-ratio-correction(map-get($variant, "color"), map-get($variant, "bg"), abs($alert-color-scale), $state);
  $link-color: map-get($variant, "link-color");

  .alert-#{$state} {
    --#{$variable-prefix}alert-color: #{$color};
    --#{$variable-prefix}alert-bg: #{$background};
    --#{$variable-prefix}alert-border-color: #{$border-color};
    --#{$variable-prefix}alert-link-color: #{$link-color};
  }
}
// scss-docs-end alert-variant-mixin
