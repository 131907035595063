// Here you can add other styles
.btn-danger,
.btn-danger,
.btn-success,
.btn-info,
.btn-warning {
    color: #fff;
}
.break {
    flex-basis: 100%;
    height: 0;
}

.tracking-detail {
    padding: 3rem 0;
}
#tracking {
    margin-bottom: 1rem;
}
[class*="tracking-status-"] p {
    margin: 0;
    font-size: 1.1rem;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
}
[class*="tracking-status-"] {
    padding: 1.6rem 0;
}
.tracking-list {
    border: 1px solid #e5e5e5;
}
.tracking-item {
    border-left: 1px solid #e5e5e5;
    position: relative;
    padding: 2rem 1.5rem 2rem 2.5rem;
    font-size: 0.9rem;
    margin-left: 3rem;
    min-height: 5rem;
}
.tracking-item:last-child {
    padding-bottom: 4rem;
}
.tracking-item .tracking-date {
    margin-bottom: 0.5rem;
}
.tracking-item .tracking-date span {
    color: #888;
    font-size: 85%;
    padding-left: 0.4rem;
}
.tracking-item .tracking-content {
    padding: 0.5rem 0.8rem;
    background-color: #f4f4f4;
    border-radius: 0.5rem;
}
.tracking-item .tracking-icon {
    position: absolute;
    left: -1.35rem;
    width: 2.6rem;
    height: 2.6rem;
    text-align: center;
    border-radius: 50%;
    background-color: #fff;
    color: #e5e5e5;
}
@media (min-width: 992px) {
    .tracking-item {
        margin-left: 10rem;
    }
    .tracking-item .tracking-date {
        position: absolute;
        left: -10rem;
        width: 7.5rem;
        text-align: right;
    }
    .tracking-item .tracking-date span {
        display: block;
    }
    .tracking-item .tracking-content {
        padding: 0;
        background-color: transparent;
    }
}
@media print {
    .header,.footer ,.client-data ,.card-footer,.back-btn{
        display: none;
    }
    .card{
        border: 0;
    }
}